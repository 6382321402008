const state = {
    loginState: false,
    loginType: '001',
    referApis: [],
    address:'',
    customIndex: 1,
    signer:'',
    orderListObj:{},
    loginMess:{},
    netWork:'',
    screenWidth:document.documentElement.clientWidth
  };
  export default state