const routes = [
// {
//   path: '/',
//   redirect: '/shop'
// },
{
  path: '/',
  name: "shop",
  component: (resolve) => require(['@/views/shop/shopList.vue'], resolve),
  meta: {
    title: '专利商城',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/cooperative',
  name: "cooperative",
  component: (resolve) => require(['@/views/cooperative/cooperative.vue'], resolve),
  meta: {
    title: '合作机构',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/explosivePatent',
  name: "explosivePatent",
  component: (resolve) => require(['@/views/explosivePatent/explosiveList.vue'], resolve),
  meta: {
    title: '爆炸专利',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/platform',
  name: "platform",
  component: (resolve) => require(['@/views/platform/platformList.vue'], resolve),
  meta: {
    title: '关于平台',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/regulatory',
  name: "regulatory",
  component: (resolve) => require(['@/views/regulatory/regulatoryList.vue'], resolve),
  meta: {
    title: '法规文件',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/contractMess',
  name: "contractMess",
  component: (resolve) => require(['@/views/regulatory/contractMess.vue'], resolve),
  meta: {
    title: '合同文本',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/shop',
  name: "shop",
  component: (resolve) => require(['@/views/shop/shopList.vue'], resolve),
  meta: {
    title: '专利商城',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/shopDetail',
  name: "shopDetail",
  component: (resolve) => require(['@/views/shop/shopDetail.vue'], resolve),
  meta: {
    title: '专利详情',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/purchaseProcess',
  name: "purchaseProcess",
  component: (resolve) => require(['@/views/shop/purchaseProcess.vue'], resolve),
  meta: {
    title: '购买发布流程',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/rule',
  name: "rule",
  component: (resolve) => require(['@/views/shop/rule.vue'], resolve),
  meta: {
    title: '交易规则',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/forget',
  name: "forget",
  component: (resolve) => require(['@/views/shop/forgetPassword.vue'], resolve),
  meta: {
    title: '忘记密码',
    keepAlive: false // 需要被缓存
  }
},
{
  path: '/applyJoin',
  name: "applyJoin",
  component: (resolve) => require(['@/views/shop/applyJoin.vue'], resolve),
  meta: {
    title: '申请加盟',
    keepAlive: false // 需要被缓存
  }
 },
 {
  path: '/buy',
  name: "buy",
  component: (resolve) => require(['@/views/shop/buy.vue'], resolve),
  meta: {
    title: '我要购买',
    keepAlive: false // 需要被缓存
  }
 },
 {
  path: '/publish',
  name: "publish",
  component: (resolve) => require(['@/views/shop/publish.vue'], resolve),
  meta: {
    title: '我要发布',
    keepAlive: false // 需要被缓存
  }
 },
 {
  path: '/publishCheck',
  name: "publishCheck",
  component: (resolve) => require(['@/views/shop/publishCheck.vue'], resolve),
  meta: {
    title: '发布审核',
    keepAlive: false // 需要被缓存
  }
 },
 {
  path: '/personCeneter',
  name: "personCeneter",
  component: (resolve) => require(['@/views/shop/personCenter.vue'], resolve),
  meta: {
    title: '个人中心',
    keepAlive: false // 需要被缓存
  }
 },
 {
  path: '/changePresonMess',
  name: "changePresonMess",
  component: (resolve) => require(['@/views/shop/changePresonMess.vue'], resolve),
  meta: {
    title: '个人信息修改',
    keepAlive: false // 需要被缓存
  }
 },
 {
  path: '/pdf',
  name: "pdf",
  component: (resolve) => require(['@/views/shop/pdf.vue'], resolve),
  meta: {
    title: '文件查看',
    keepAlive: false // 需要被缓存
  }
 }
]

export default routes;
