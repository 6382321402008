import * as types from './mutation-types'
export default {
  [types.CHANGELOGINSTATE](state, msg) {
    state.loginState = msg.loginState;
    state.address = msg.address
    state.signer = msg.signer
    console.log(state)
  },
  [types.CHANGECUSTOMINDEX](state, msg) {
    state.customIndex = msg;
  },
  [types.CHANGEORDER](state, msg) {
    state.order = msg;
  },
  [types.CHANGELOGINMESS](state, msg){
    state.loginMess = JSON.parse(JSON.stringify(msg))
  },
  [types.NETWORKCHAGE](state, msg){
    state.netWork = msg
  },
  [types.CLEARMESS](state, msg){
    state.address = '';
    state.loginType = '001';
    state.customIndex = 1;
    state.loginMess = {};
    state.netWork = '';
  },
  changereferApis(state, msg) {
    if (!!state.referApis.length) {
      let Index = state.referApis.findIndex(item => {
        return item.page == msg.page
      });
      if (!!Index) {
        state.referApis.push(msg)
      }
    } else {
      state.referApis.push(msg)
    }

  }
}
