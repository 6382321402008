<template>
  <div id="app" ref="appBox">
    <!-- nav部分 -->
    <div class="app_tar">
      <tarnav ref="tarnav" @signOut="signOut" @walletSure="walletSure"></tarnav>
    </div>
    <!-- 主体部分 -->
    <div class="app_maincontent">
      <keep-alive :key="$route.fullPath" v-if="$route.meta.keepAlive">
        <router-view />
      </keep-alive>
      <router-view :key="$route.fullPath" v-else></router-view>
    </div>
    <!-- footer部分 -->
    <tigerFooter ref="footer"></tigerFooter>
    
  </div>
</template>
<script>
import tarnav from "@/components/tarnav.vue";
import tigerFooter from "@/components/footer.vue";
export default {
  data() {
    return {
      appcs: false,
      show: false,
      isSign: true,
    };
  },
  components: {
    tarnav,
    tigerFooter,
  },

  mounted() {
    this.getFontSize()
    window.addEventListener('resize', () => this.getFontSize());
  },
  watch: {
    "$route.path"(newValue) {
      this.appcs = false;
      window.scrollTo(0, 0);
      // this.$refs.tarnav.matchRouter(newValue);
      // this.$refs.footer.matchRouter(newValue);
    },
  },
  methods: {
    getFontSize(){
       document.documentElement.style.fontSize = 
       document.documentElement.clientWidth > 1920 
        ? 1920 / 1100 * 10 + 'px'
        : document.documentElement.clientWidth / 1100 * 10 + 'px'
    },
    metaSucc() {
      this.$refs.tarnav.getAddress();
    },
    signOut() {
      this.isSign = false;
      this.show = true;
    },
    walletSure() {
      this.isSign = true;
      this.show = true;
    },
  },
};
</script>
<style lang="less" scoped>
@import "./css/style.less";
#app {
  font-family: "OpenSans_SemiCondensed-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  min-width: 1200px;
  height: 100%;
  // font-size:120%;
}
.app_tar {
  width: 100%;
  height: 100px;
}
.app_maincontent {
  width: 100%;
  min-height: calc(100% - 428px);
  // height: 100%;
}
/deep/.el-message .el-message--warning {
  z-index: 8888;
}
#nav {
  margin: 0;
  padding: 0;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
