<!--  -->
<template>
  <div class="nav">
     <div class="top">
       <div v-if="!name" class="top1">
        <span class="top2">尊敬的用户，请</span>
        <span class="top3" @click="login">登录</span>
        <span>|</span>
        <span class="top4" @click="reg">注册</span>  
       </div> 
       <div v-else class="top1">
        <span class="top2">尊敬的{{name}}用户，欢迎您</span>
        <span class="top3" @click="reg1">我的</span>  
       </div>    
     </div>
     <div class="bottom">
        <span class="bottom1">
            <span class="bottom2">高智量</span>
            <span class="bottom3">专利开放许可平台</span>
        </span>
        <span class="bottom4">
           <span :class="['bottom5', currentUrl == '/' ? 'current': '']" @click="pushUrl('/')">
             专利商城
             <span class="bottom11"></span>
           </span>
           <span :class="['bottom5', currentUrl == '/explosivePatent' ? 'current': '']" @click="pushUrl('/explosivePatent')">
            爆款专利
             <span class="bottom11"></span>
           </span>
           <el-dropdown placement="bottom-start">
           <span :class="['bottom5','el-dropdown-link', currentUrl == '/regulatory'||   currentUrl == '/contractMess'? 'current': '']">
            法规文件
             <span class="bottom11"></span>
           </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="pushUrl('/regulatory')">法律法规</el-dropdown-item>
              <el-dropdown-item @click.native="pushUrl('/contractMess')">合同文件</el-dropdown-item>
             </el-dropdown-menu>
          </el-dropdown>
           <span :class="['bottom5', currentUrl == '/cooperative' ? 'current': '']" @click="pushUrl('/cooperative')">
            合作机构
             <span class="bottom11"></span>
           </span>
           <el-dropdown placement="bottom-start">
           <span :class="['bottom5','el-dropdown-link', currentUrl == '/rule'||  currentUrl == '/platform'  ? 'current': '']">
            关于平台
             <span class="bottom11"></span>
           </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="pushUrl('/platform')">关于平台</el-dropdown-item>
              <el-dropdown-item @click.native="pushUrl('/rule')">交易规则</el-dropdown-item>
             </el-dropdown-menu>
          </el-dropdown>
           
        </span>
        <span class="bottom6">
           <i class="iconfont icon-chaxun bottom9"></i>
           <input v-model="searchName" type="text" class="bottom7" placeholder="请输入您想搜索的专利">
           <span class="bottom8" @click.stop="search()"><i class="iconfont icon-chaxun bottom10"></i></span>
        </span>
     </div>
     <loginMoudle ref="loginMoudle" @closeMoudle="closeMoudle" v-if="loginStatus" />
  </div>
</template>

<script>
import router from '../router'
import loginMoudle from "./login.vue"
export default {
 components:{
  loginMoudle
 },
 data() {
  return {
    titleList:[
    {
      title:'专利商城',
      status: true,
      url:'/'
     },
     {
      title:'爆款专利',
      status: false,
      url:'/explosivePatent'
     },
     {
      title:'法规文件',
      status: false,
      url:'/regulatory'
     },
     {
      title:'合作机构',
      status: false,
      url:'/cooperative'
     },
     {
      title:'关于平台',
      status: false,
      url:'/platform'
     }
   ],
   loginStatus: false,
   name: '',
   searchName:'',
   currentUrl:'/shop'
  }
 },
 watch: {
    "$route.path"(newValue) {
       this.checkUrl(newValue)
    },
  },
 created() {
 
 },

 mounted() {
   if(localStorage.getItem("token")){
    this.name = localStorage.getItem("username")
   }
   this.$bus.$on("loginBegin", ()=>{
      this.login();
   })
   this.$bus.$on("out", ()=>{
      this.name = '';
      this.$router.push("/")
   })
   this.checkUrl()
 },
 methods:{
  checkUrl(mess){
   let m = mess || this.$route.path;
   console.log(m)
   this.currentUrl = m;
  // this.titleList.forEach(item => item.status = item.url == m)
  },
   pushUrl(url){
    console.log(655656565656565656, url)
     this.currentUrl = '/'
     this.$router.push(url)
   },
   login(){
     this.loginStatus = true;
     this.$nextTick(() =>{
       this.$refs.loginMoudle.loginChange();
     })
    
   },
   reg(){
     this.loginStatus = true;
     this.$nextTick(() =>{
       this.$refs.loginMoudle.loginChange1();
     })
   },
   reg1(){
     this.$router.push('/personCeneter')
   },
   closeMoudle(name){
    console.log(name)
    this.loginStatus = false;
    this.name = name;
   },
   search(){
     let searchName = this.searchName;
     let name = this.$route.name;
    if(name != 'explosivePatent'){
       this.$router.push("/");
       setTimeout(()=>{
         this.$bus.$emit("serachName", searchName)
       },500)
    } 
    else this.$bus.$emit("serachName", searchName)
   }
 }
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
  .nav{
    position: fixed;
    width: 100%;
    top: 0;
    background-color: white;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5);
    z-index: 9;
    .top{
      height: 30px;
      background: #F9FAFB;
      
    }
    .top1{
      max-width: 1160px;
      margin: auto;
      font-size: 12px;
      line-height: 30px;
    }
    .top3{
      color: #4289FF;
      margin: 0 15px 0 8px;
    }
    .top4{
      color: #292930;
      margin-left: 15px;
    }
    .bottom{
      height: 90px;
      max-width: 1160px;
      margin: auto;
      padding-top: 17px;
      display: flex;
      position: relative;
    }
    .bottom1{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 152px;
      margin-right: 40px;
    }
    .bottom2{
     font-size: 34px;
     color: #4289FF;
     line-height: 47px;
     letter-spacing: 16px;
    }
    .bottom3{
      color: #4289FF;
      font-size: 17px;
      line-height: 17px;
    }
    .bottom4{
      padding-top: 18px;
      color: #20203E;
      font-size: 20px;
    }
    .bottom5{
      margin-right: 60px;
      position: relative;
    }
    .current{
      color: #4289FF;
    }
    .bottom6{
      width: 264px;
      position: absolute;
      right: 0;
      top: 30px;
      border: 1px solid #619AFF;
      height: 38px;
    }
    .bottom7{
      outline: none;
      border: none;
      line-height: 36px;
      padding-left: 36px;
      font-size: 12px;
    }
    .bottom8{
      display: inline-block;
      width: 74px;
      height: 38px;
      background: #4289FF;
      position: absolute;
      right: -1px;
      top: -1px;
      text-align: center;
      line-height: 38px;
    }
    .bottom9{
      color: #ccc;
      left: 15px;
      top: 10px;
      display: inline-block;
      position: absolute;
    }
    .bottom10{
     color: white;
     font-size: 27px; 
    }
    .bottom11{
      position: absolute;
      width: 2px;
      height: 8px;
      border: 1px solid #E4E7ED;
      right: -30px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  /deep/.el-dropdown{
    font-size: 20px;
  }
</style>