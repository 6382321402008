import * as types from './mutation-types'
export const changeDate = ({commit}, obj) =>{
    commit(types.CHANGELOGINSTATE, obj)
}
export const changeCustomIndex = ({commit}, obj) =>{
    console.log("vuex  changeCustomIndex")
    commit(types.CHANGECUSTOMINDEX, obj)
}
export const changeOrder = ({commit}, obj) =>{
    commit(types.CHANGEORDER, obj)
}
export const loginMess = ({commit}, obj) =>{
    commit(types.CHANGELOGINMESS, obj)
}
export const networkChage = ({commit}, obj) =>{
    commit(types.NETWORKCHAGE, obj)
}
export const clearMess = ({commit}, obj) =>{
    commit(types.CLEARMESS, obj)
}