import Vue from 'vue'
import App from './App.vue'
import store from '@/store'
import VueRouter from "vue-router";
import "../public/static/css/font.css"
import "@/assets/icon/iconfont.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//require('./common/element')
Vue.use(VueRouter); //路由
Vue.use(ElementUI)
import router from "./router";
window.eventBus = new Vue();
Vue.prototype.$bus = new Vue();
Vue.config.productionTip = false


// 路由发生变化时改变对应的title
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  // if (to.meta.title) {
  //   document.title = to.meta.title;
  // }
  next()
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
