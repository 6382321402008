import Vue from "vue";
import Vuex from "vuex";
import createVuexAlong from 'vuex-along' //开启页面刷新 vuex 数据不恢复为原始数据
import mutations from './mutations'
import * as getters from './getters'
import * as actions from './action'
import state from './state'
Vue.use(Vuex);
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins: [createVuexAlong({
    name: "panda-vuex-along", // 设置保存的集合名字，避免同站点下的多项目数据冲突
    local: {
      list: [],
      isFilter: false // 过滤模块 ma 数据， 将其他的存入 localStorage
    },
    session: {
      list: ["loginState","address"], // 保存loginState到 sessionStorage
    },
    justSession: true //true 只开启session 存储，
  })]
});
