import VueRouter from "vue-router";
var childrenRouter = []; // 引用各个模块路由数组对象
const requireComponent = require.context('./files', false, /\.js$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  childrenRouter.push(...componentConfig.default);

})
export default new VueRouter({
  mode: 'history',
  routes: childrenRouter
})
