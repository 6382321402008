import { apiRequestList  } from "./axios"
//查询常用信息
export const parameter = ()=>{
   return apiRequestList.$post('/Index/parameter',{})
}
//获取列表
export const getShopList = (params)=>{
   return apiRequestList.$post('/Index/index',params)
}
//获取热门推荐专利
export const getHotShopList = (params)=>{
   return apiRequestList.$post('/Index/patent',params)
}
//获取合作机构
export const getCooperationList = (params)=>{
   return apiRequestList.$post('/Index/cooperation',params)
}
//我要购买  Index/mybuy
export const mybuy = (params)=>{
   return apiRequestList.$post('/Index/mybuy',params)
}
//服务条款
export const service = (params)=>{
   return apiRequestList.$post('Index/service',params)
}
//联系我们
export const aboutus = (params)=>{
   return apiRequestList.$post('/Index/aboutus',params)
}
//申请加盟
export const patentjoin = (params)=>{
   return apiRequestList.$post('/Index/patentjoin',params)
}
//法规文件
export const contract = (params)=>{
   return apiRequestList.$post('/Index/contract',params)
}
//交易规则
export const trading_rules = (params)=>{
   return apiRequestList.$post('/Index/trading_rules',params)
}
//专利发布审核-第二步提交
export const reviewadd = (params)=>{
   return apiRequestList.$post('/Index/reviewadd',params)
}
//专利详情
export const patentinfo = (params)=>{
   return apiRequestList.$post('/Index/patentinfo',params)
}
//User/sendsms
//专利详情
export const sendsms = (params)=>{
   return apiRequestList.$post('/User/sendsms',params)
}
//注册  
export const register = (params)=>{
   return apiRequestList.$post('/User/register',params)
}
//登录 
export const login = (params)=>{
   return apiRequestList.$post('/User/login',params)
}
//登录 User/login
export const smslogin = (params)=>{
   return apiRequestList.$post('/User/smslogin',params)
}
//忘记密码1 User/retrieve1
export const retrieve1 = (params)=>{
   return apiRequestList.$post('/User/retrieve1',params)
}
//忘记密码1 User/retrieve1
export const retrieve2 = (params)=>{
   return apiRequestList.$post('/User/retrieve2',params)
}
//忘记密码1 User/retrieve1
export const retrieve3 = (params)=>{
   return apiRequestList.$post('/User/retrieve3',params)
}
//专利购买详情
export const patentorder = (params)=>{
   return apiRequestList.$post('/Index/patentorder',params)
}
//专利购买详情
export const orderpatentinfo = (params)=>{
   return apiRequestList.$post('/Index/orderpatentinfo',params)
}
//开始购买  Index/orderpay
export const orderpay = (params)=>{
   return apiRequestList.$post('/Index/orderpay',params)
}
//文件上传 
export const upload = (params)=>{
   return apiRequestList.$post('/Common/upload',params)
}
//专利发布 
export const release = (params)=>{
   return apiRequestList.$post('/Index/release',params)
}
//我的发布 
export const myrelease = (params)=>{
   return apiRequestList.$post('/Index/myrelease',params)
}
//关于我们信息/api/Index/abouts
export const abouts = (params)=>{
   return apiRequestList.$post('/Index/abouts',params)
}
//获取我的发布  保存之后的
export const review = (params)=>{
   return apiRequestList.$post('/Index/review',params)
}
//收藏
export const collected = (params)=>{
   return apiRequestList.$post('/Index/collectionadd',params)
}
export const cancleCollected = (params)=>{
   return apiRequestList.$post('/Index/collectiondel',params)
}
//收藏列表
export const mycollection = (params) =>{
   return apiRequestList.$post('/Index/mycollection',params)
}
export const getUserInfo = (params)=>{
   return apiRequestList.$post('/user/info',params)
}
export const changeUserInfo = (params)=>{
   return apiRequestList.$post('/user/edit',params)
}
//联系方式
export const getAddress =  (params)=>{
   return apiRequestList.$post('/Index/information',params)
}
export const orderpatentdel =  (params)=>{
   return apiRequestList.$post('/Index/orderpatentdel',params)
}
//删除发布
export const myreleasedel =  (params)=>{
   return apiRequestList.$post('/Index/myreleasedel',params)
}
//订单管理
export const myseller =  (params)=>{
   return apiRequestList.$post('/Index/myseller',params)
}
//轮播图
export const banner =  (params)=>{
   return apiRequestList.$post('/Index/banner',params)
}