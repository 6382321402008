<template>
  <div class="loginMoudle">
     <div v-if="login" class="mainBox">
       <span @click="$emit('closeMoudle')"><i class="iconfont icon-shanchu"></i></span>
       <div class="m1">
         <div class="m11">
             <span @click="type= 'pass'" :class="['m12',type == 'pass' ? 'current':'']">密码登录</span>
             <span @click="type= 'mobile'" :class="[type == 'mobile' ? 'current':'']">验证码登录</span>
         </div>
         <div @click="type1= '1'" v-if="type== 'pass'" :class="['m2', type1=='1'?'checked':'']">
            <i class="iconfont icon-people m21"></i>
            <input class="m22" v-model="loginMess.username" type="text" placeholder="请输入账号"> 
         </div>
         <div @click="type1= '2'" v-if="type== 'pass'" :class="['m2', type1=='2'?'checked':'']">
            <i class="iconfont icon-3702mima m21"></i>
            <input class="m22" v-model="loginMess.password" type="password" placeholder="密码"> 
         </div>
         <div @click="type1= '3'" v-if="type== 'mobile'" :class="['m2', type1=='3'?'checked':'']">
            <i class="iconfont icon-people m21"></i>
             <input v-model="loginMess.mobile" @input="changePhone1"  :class="['l23', type2 == 'mobile'? 'checked':'', mess.mobile.errorMess ? 'error': '']" type="number" placeholder="请输入手机号">
         </div>
         <div @click="type1= '4'" v-if="type== 'mobile'" :class="['m2', type1=='4'?'checked':'']">
            <!-- <i class="iconfont icon-3702mima m21"></i> -->
            <input v-model="loginMess.verify" @input="messInput('verify')"   :class="['l23', 'l26', type2 == 'verify'? 'checked':'']" type="text" placeholder="请输入短信验证码">
            <span @click="getNum1" :class="['l25', telStatus1? 'active':'']">{{num1 > 0 ? `再次获取(${num1})` : '获取验证码'}}</span>
         </div>
         <div class="m30" @click="loginBegin">登录</div>
         <div class="m4">
            <el-checkbox v-model="loginMess.checked">记住登录</el-checkbox> 
            <span @click="pushRouter">忘记密码?</span>
         </div>
         <div class="m5">
            还没有账号？<span class="m51" @click="loginChange1">立即注册</span>  
         </div>
       </div> 
     </div>
     <div v-if="reg" class="mainBox1">
            <span @click="$emit('closeMoudle')"><i class="iconfont icon-shanchu"></i></span>

          <div class="l1">注册</div>
          <div class="l2">
             <div class="l21"> 
                <span class="l22">用户名</span> 
                <input v-model="obj.username" @focus="type2 = 'username'" @input="messInput('username')" @blur="type2 = ''" :class="['l23', type2 == 'username'? 'checked':'', mess.username.errorMess ? 'error': '']" type="text" placeholder="请输入用户名">
                <span class="l231" v-if="mess.username.errorMess"> <img class="l231"  src="../assets/images/errorIcon.png"> {{mess.username.errorMess}}</span>
             </div>
             <div class="l21"> 
                <span class="l22">密码</span> 
                <input v-model="obj.password" @focus="type2 = 'password'" @input="messInput('password')" @blur="type2 = ''" :class="['l23', type2 == 'password'? 'checked':'', mess.password.errorMess ? 'error': '']" type="passport" placeholder="请输入密码">
                <span class="l231" v-if="mess.password.errorMess"> <img class="l231"  src="../assets/images/errorIcon.png"> {{mess.password.errorMess}}</span>

             </div>
             <div class="l21"> 
                <span class="l22">确认密码</span> 
                <input v-model="obj.password_again" @focus="type2 = 'password_again'" @input="messInput('password_again')" @blur="type2 = ''" :class="['l23', type2 == 'password_again'? 'checked':'', mess.password_again.errorMess ? 'error': '']" type="passport" placeholder="请再次输入密码">
                <span class="l231" v-if="mess.password_again.errorMess"> <img class="l231"  src="../assets/images/errorIcon.png"> {{mess.password_again.errorMess}}</span>
             </div>
             <div class="l21"> 
                <span class="l22">姓名 (选填)</span> 
                <input v-model="obj.nickname" @focus="type2 = 'nickname'" @blur="type2 = ''"   :class="['l23', type2 == 'nickname'? 'checked':'', mess.nickname.errorMess ? 'error': '']" type="text" placeholder="请输入姓名">
                <span class="l231" v-if="mess.nickname.errorMess"> <img class="l231"  src="../assets/images/errorIcon.png"> {{mess.nickname.errorMess}}</span>
             </div>
             <div class="l21"> 
                <span class="l22">手机号</span> 
                <input v-model="obj.mobile" @input="changePhone" @focus="type2 = 'mobile'" @blur="type2 = ''" :class="['l23', type2 == 'mobile'? 'checked':'', mess.mobile.errorMess ? 'error': '']" type="number" placeholder="请输入手机号">
                <span class="l231" v-if="mess.mobile.errorMess"> <img class="l231"  src="../assets/images/errorIcon.png"> {{mess.mobile.errorMess}}</span>
             </div>
             <div class="l21"> 
                <span class="l22">验证码</span> 
                <span class="l24">
                  <input v-model="obj.verify" @input="messInput('verify')"  @focus="type2 = 'verify'" @blur="type2 = ''" :class="['l23', 'l26', type2 == 'verify'? 'checked':'', mess.verify.errorMess ? 'error': '']" type="text" placeholder="请输入短信验证码">
                  <span @click="getNum" :class="['l25', telStatus? 'active':'']">{{num > 0 ? `再次获取(${num})` : '获取验证码'}}</span>
                  <span class="l231" v-if="mess.verify.errorMess"> <img class="l231"  src="../assets/images/errorIcon.png"> {{mess.verify.errorMess}}</span>
                </span>
             </div>
             <div class="l3" @click="conform">注册</div>
             <div class="l4">
                <el-checkbox v-model="mess.checked"></el-checkbox> 
                <span class="l41">同意并遵守<span class="l42" @click="pushUrl">服务条款</span></span>
             </div>
          </div>
          <span class="l9">已有账号，<span class="l91" @click="loginChange">立即登录</span></span>
     </div>
  </div>
</template>

<script>
import { sendsms, register, login, smslogin } from "../api/common"
export default {
data() {
return {
  type: 'pass',
  type1: 1,
  login: false,
  reg: true,
  type2:'username',
  obj:{

  },
  mess:{
    username:{ value:'', status:true, errorMess:'' },
    password:{ value:'', status:true, errorMess:'' },
    password_again:{ value:'', status:true, errorMess:'' },
    nickname:{ value:'', status:true, errorMess:'' },
    mobile:{ value:'', status:true, errorMess:'' },
    verify:{ value:'', status:true, errorMess:'' },
    checked: false
  },
  loginMess:{
     checked: false,
     username: localStorage.getItem('username'),
     password: localStorage.getItem('password')
  },
  smslogin:{

  },
  telStatus:false,
  telStatus1: false,
  num: 0,
  num1:0
}
},
created() {

},
mounted() {
  if(localStorage.getItem('password')){
    this.loginMess.checked = true;
    this.loginMess.password = localStorage.getItem('password');
  }
},
methods:{
    loginChange(){
      this.login =  true;
      this.reg= false
    },
    loginChange1(){
      this.login =  false;
      this.reg= true
    },
    getNum(){
      if(!this.telStatus || this.num > 0)return;
      sendsms({mobile: this.obj.mobile}).then(res =>{
        console.log(res);
        this.obj.verify_id = res.data.verify_id
        this.num = 60;
        let f = setInterval(() => {
          if(this.num == 0)return clearInterval(f);
          this.num --;
        }, 1000);
      })
    },
    getNum1(){
      if(!this.telStatus1 || this.num1 > 0)return;
      sendsms({mobile: this.loginMess.mobile}).then(res =>{
        console.log(res);
        this.loginMess.verify_id = res.data.verify_id
        this.num1 = 60;
        let f = setInterval(() => {
          if(this.num1 == 0)return clearInterval(f);
          this.num1 --;
        }, 1000);
      })
    },
    changePhone(num){
     console.log(this.obj.mobile)
     let n = this.obj.mobile;
     let flag = /^[1][3-9][\d]{9}/.test(n);
     this.telStatus = flag;
     flag && (this.mess.mobile.errorMess = '');
     console.log(flag)
    },
    changePhone1(){
     console.log(this.loginMess.mobile)
     let n = this.loginMess.mobile;
     let flag = /^[1][3-9][\d]{9}/.test(n);
     this.telStatus1 = flag;
    },
    messInput(type){
      console.log(type)
      if(type == 'password' || type == 'password_again'){
         this.mess[type].errorMess = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(this.obj[type]) ? '':'请填写8~20数字跟字母组合的密码'
      }
      else this.obj[type] && (this.mess[type].errorMess = '')

    },
    conform(){
      let flag = true;
      this.mess.username.errorMess = !this.obj.username ? '用户名必填':'';
      this.mess.password.errorMess = !this.checkPasspord(this.obj.password) ? '请填写8~20数字跟字母组合的密码':''
      this.mess.password_again.errorMess = !this.checkPasspord(this.obj.password_again)  ? '请填写8~20数字跟字母组合的密码':''
      this.mess.verify.errorMess = !this.obj.verify || this.obj.verify.length != 4 ? '请填写正确的验证码':''
      this.mess.mobile.errorMess = !this.telStatus ? '请填写正确的手机号':''
      if(!this.mess.checked)return this.$message.error("请同意服务条款");
      if(this.obj.password != this.obj.password_again)this.$message.error("两次输入密码不一致，请核对")
      if(!this.obj.username || !this.obj.password || !this.obj.password_again || !this.obj.verify || this.obj.verify.length != 4 || !this.telStatus)return;
      register(this.obj).then(res =>{
        console.log(res);
        if(res.code == 0)return this.$message.error(res.msg)
        this.$message.success("注册成功请登录");
        this.loginChange();
      })
      
    },
    checkPasspord(mess){
      return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(mess)
    },
    loginBegin(){
    if(this.type == 'pass'){
       console.log(this.loginMess, this.loginMess.password, this.loginMess.username)
      if(!this.loginMess.password || !this.loginMess.username)return this.$message.error("请输入正确用户名和密码");
      if(!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(this.loginMess.password))return this.$message.error("请填写8~20数字跟字母组合的密码");
      login(this.loginMess).then(res =>{
        console.log(res);
        if(res.code == 0)return this.$message.error(res.msg)
        this.$message.success('登录成功！');
       localStorage.setItem('username', this.loginMess.username);
       localStorage.setItem('userMess', JSON.stringify(res.data));
       this.loginMess.checked && localStorage.setItem('password', this.loginMess.password)
       localStorage.setItem('token', res.data.token);
       this.$emit('closeMoudle',this.loginMess.username)
      })
     }
     else{
       if(!this.loginMess.mobile || !this.loginMess.verify)return this.$message.error("请输入正确手机号码和验证码");
       if(this.loginMess.verify.length != 4)return this.$message.error("请输入正确的验证码");
       if(!this.telStatus1)return this.$message.error("请输入正确正确的手机号码");
       smslogin(this.loginMess).then(res =>{
         console.log(res);
         if(res.code == 0)return this.$message.error(res.msg)
         this.$message.success('登录成功！');
         localStorage.setItem('username', res.data.username);
         localStorage.setItem('token', res.data.token);
         this.$emit('closeMoudle',res.data.username)
         localStorage.setItem('userMess', JSON.stringify(res.data));
       })
     }
      
    },
    pushRouter(){
      this.$emit('closeMoudle')
      this.$router.push('/forget')
    },
    pushUrl(){
      this.$emit('closeMoudle')
      this.$router.push('/rule')
    }
}
}
</script>
<style lang="less"  scoped>
.loginMoudle{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  .mainBox{
    position: absolute;
    width: 780px;
    height: 452px;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    background: url("../assets/images/loginImg.png");
    background-size: 100% 100%;
    .m1{
      position: absolute;
      width: 300px;
      height: 100%;
      right: 40px;
      padding-top: 40px;
      .m11{
        font-size: 24px;
        color: #333333;
        margin-bottom: 30px;
        line-height: 33px;
      }
      .m12{
        margin-right: 30px;
      }
      .current{
        color: #4289FF;
        position: relative;
      }
      .current::after{
        content: '';
        position: absolute;
        width: 30px;
        height: 4px;
        background: #4289FF;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .m2{
      width: 300px;
      height: 48px;
      background: #FFFFFF;
      border: 1px solid #E7E9EC;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .m21{
        float: left;
        margin: 0 10px 0 14px;
        color: #ccc;
        font-size: 14px;
      }
      .m22{
        height: 100%;
        width: 246px;
        outline: none;
        border: none;
      }
    }
    .m2.checked{
      border: 1px solid #1F74FF;

    }
    .m30{
      width: 300px;
      height: 48px;
      background: #4289FF;
      margin-top: 30px;
      color: #FFFFFF;
      font-size: 18px;
      text-align: center;
      line-height: 48px;
      margin-bottom: 16px;
    }
    .m4{
      font-size: 14px;
      color: #7B7D81;
      display: flex;
      justify-content: space-between;  
    }
    .m5{
      margin-top: 20px;
      font-size: 14px;
      color: #7B7D81;
      border-top: 1px solid #E7E9EC;
      text-align: center;
      padding-top: 20px;
    }
    .m51{
       color: #1F74FF;
    }
  }
  .mainBox1{
    position: absolute;
    width: 900px;
    height: 524px;
    background-image: url("../assets/images/regBase.png");
    background-size: 100% 100%;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    text-align: center;
    .l1{
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #E7E9EC;
      color: #2C2D2F;
      font-size: 22px;  
    }
    .l2{
      margin-top: 20px;
      padding-left: 184px;
      .l21{
        margin-bottom: 18px;
        text-align: left;
      }
      .l22{
        display: inline-block;
        width: 100px;
        font-size: 14px;
        color: #2C2D2F;
        text-align: right;
        margin-right: 20px;
      }
      
      .l231{
         font-size: 14px;
         color: #FF5E1B;
         display: flex;
         align-items: center;
         width: 258px;
         float: right;
         margin-right: 10px;
         height: 40px;
         img{
          width: 16px;
          height: 16px;
         }
      }
      .l232{
        margin-right: 2px;
      }
      .l24{
        position: relative;
      }
      .l26{
        padding-right: 150px;
      }
      .l25{
         position: absolute;
         /* float: right; */
         right: 10px;
         width: 82px;
         height: 26px;
         background: #DEE1E6;
         font-size: 14px;
         color: white;
         line-height: 26px;
         text-align: center;
         top: -1px; 
      }
      .l25.active{
        background-color: #4289FF;
      }
      .l3{
        width: 320px;
        height: 40px;
        background: #4289FF;
        font-size: 14px;
        color: white;
        text-align: center;
        line-height: 40px;
        margin-left: 122px;
      }
      .l4{
        font-size: 14px;
        color: #7B7D81;
        margin-top: 18px;
        margin-left: 122px;
        width: 150px;
      }
      .l41{
        margin-left: 8px;
      }
      .l42{
        color: #1F74FF;
      }
    }
    
    .l9{
      position: absolute;
      font-size: 14px;
      color: #7B7D81;
      right: 34px;
      top: 18px;
      .l91{
         color: #1F74FF;
      }
    }
  }
  .l23{
     height: 40px;
     width: 320px;
     border: none;
     outline: none;
     border: 1px solid #E7E9EC;
     font-size: 14px;
     color: #2C2D2F;
     padding-left: 14px;
  }
  .l23.checked{
    border: 1px solid #4289FF;
  }
  .l23.error{
    border: 1px solid #FF5E1B;
  }
  .l26{
        padding-right: 150px;
  }
  .l25{
         position: absolute;
         /* float: right; */
         right: 10px;
         width: 82px;
         height: 26px;
         background: #DEE1E6;
         font-size: 14px;
         color: white;
         line-height: 26px;
         text-align: center;
         top: -1px; 
      }
      .l25.active{
        background-color: #4289FF;
      }
  .m1{
    .l23{
      border: none;

    }
    .l26{
      width: 290px;
      margin-right: 0;
    }
  }
  .m2{
    position: relative;
    .l25{
      top: 10px
    }
  }
}
.icon-shanchu{
  position: absolute;
  right: -25px;
  top: -20px;
  color: white;
  font-size: 20px;
}
input[type=number] {
    -moz-appearance:textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>