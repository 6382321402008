<template>
  <div class="footer-page">
     <div class="footerTop">
       <div class="a1">
          <p class="a11">联系我们</p>
          <div class="a12"></div>
          <p ><label class="a13">咨询热线 :</label>
              <i class="iconfont icon-dianhuatianchong"></i>
              <span class="a14 a15">{{mess.telephone}}</span>
          </p>
          <p ><label class="a13" style="float: left">企业地址 :</label><span class="a14" v-html="mess.address"></span></p>
          <p ><label class="a13">企业邮箱 :</label><span class="a14">{{mess.email}}</span></p>
       </div>
       <div class="a2">
          <div class="a21">
            <span class="a22">
              <img style="width:100%;height:100%" src="../assets/images/weixin.png" alt="" srcset="">
            </span>
            <span class="a23">高智量公众号</span>
          </div>
          <div class="a21">
            <span class="a22">
              <img style="width:100%;height:100%" src="../assets/images/weixinOne.png" alt="" srcset="">
            </span>
            <span class="a23">高智量小程序</span>
          </div>
          <div class="a21">
            <span class="a22">
              <img style="width:100%;height:100%" src="../assets/images/img3.png" alt="" srcset="">
            </span>
            <span class="a23">高智量视频号</span>
          </div>
          <div class="a24">
             <div class="a25">友情链接：</div>
             <div class="a251">
              <span class="a26" @click="pushUrl('http://www.aipbox.com')">高智量官网</span>
             <span class="a26" @click="pushUrl('https://hq.aipbox.com/search/index')">知识产权协同运营中心</span>
             <span class="a26" @click="pushUrl('https://www.qhaip.com')">前海国际知识产权综合运营服务中心</span><br>
             <span class="a26" @click="pushUrl('https://zlxk.gpic.gd.cn/#/home')">广东省专利开放许可试点专区</span>
             <span class="a26" @click="pushUrl('http://www.sziprs.org.cn/szipr/ztzl/kfxksd/zlxk/')">国有企业专利“开放许可”试点平台</span>
             </div>
          </div>
       </div>
     </div>
     <div class="footerBottomm">
        粤ICP备2022096354号-1 深圳高智量数据科技有限公司
     </div>
  </div>
</template>
<script>
import { aboutus } from "../api/common"
export default {
  data() {
    return {
      mess:{},
      activePath:'',
      // iconList: ["yanjing", "tuite", "feiji", "youxi", "xiangji", "youxiang"],
    };
  },
  mounted(){
    aboutus({}).then(res =>{
      console.log(res, '=============')
      this.mess = res.data || {}
     })
  },
  methods: {
    pushUrl(i){
      let s = ['https://www.sina.com.cn/','https://www.baidu.com','https://www.163.com/'];
      window.open(i)
    }
  },
};
</script>
<style lang="less" scoped>
.footer-page {
  width: 100%;
  height: 327px;
  position: relative;
  background: #3E3E47;
  .footerTop{
    height: 267px;
    width: 1160px;
    margin: auto;
    color: white;
    padding-top: 41px;
    box-sizing: border-box;
    display: flex;
    .a1{
      width: 854px;
    }
    .a11{
      font-size: 24px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 24px;
    }
    .a12{
     border: 1px solid #292930;
     margin-top: 30px;
     margin-bottom: 30px;
    }
    .a13{
      font-size: 14px;
      margin-right: 26px;
      margin-bottom: 13px;
      display: inline-block;
    }
    .icon-dianhuatianchong{
      font-size: 20px;
      margin-right: 12px;
    }
    .a14{
      color: rgba(255,255,255,0.4800);
      font-size: 14px;
      margin-bottom: 11px;
      display: inline-block;
    }
    .a15{
      font-size: 20px;
      color: white;
    }
    .a2{
      margin-left: 10px;
    }
    .a21{
      width: 33%;
      text-align: center;
      float: left;
    }
    .a22{
      width: 117px;
      height: 117px;
      display: inline-block;
      border: 1px solid #ccc;
    }
    .a23{
      display: block;
      color: rgba(255,255,255,0.4800);
      font-size: 14px;
      margin-bottom: 30px;
    }
    .a24{
      font-size: 14px;
      display: flex;
      width: 100%;
      padding-left: 8%;
    }
    .a25{
      //margin-right: 30px;
      flex: 0 0 75px;
    }
    .a251{
      font-size: 13px;

    }
    .a26{
      margin-right: 10px;
    }
    .a26:last-child{
      margin-right: 0;
    }
  }
  .footerBottomm{
    height: 60px;
    background: #292930;
    font-size: 12px;
    line-height: 60px;
    color: rgba(255,255,255,0.3100);
    text-align: center;
  } 
}
</style>