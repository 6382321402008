import axios from 'axios' // 注意先安装哦
// 获取地址
const baseURL = 'https://admin.hqipmall.com/api'
//const baseURL = 'https://m3.gamefi789.com/api'
// 创建axios
const instance = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
//   transformResponse: [function (data) {
//   }]
})

// request 拦截器
instance.interceptors.request.use(
  config => {
    // Tip: 1 loading 动画
   // config.loading && (state.loadingFlag = true)
    // 请求开始的时候可以结合 vuex 开启全屏的 loading 动画
    // Tip: 2 处理token问题
    let token = localStorage.getItem("token");
    if (token) {
     // config.headers.token = token // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
      //config.headers['X-Token'] = token // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
    }
    // Tip: 3
    // 使用三方的url时候做特别处理
    //if (config.updatUrl) (config.baseURL = config.updatUrl) && (config.headers.updatUrl = config.updatUrl)
    return config
  },
  error => {
    // 请求错误时做些事(接口错误、超时等)
    // Tip: 4
    // 关闭loadding
    //state.loadingFlag = false

    //  1.判断请求超时
    if (
      error.code === 'ECONNABORTED' &&
      error.message.indexOf('timeout') !== -1
    ) {
      // console.log('根据你设置的timeout/真的请求超时 判断请求现在超时了，你可以在这里加入超时的处理方案')
     // Message.error('请求超时')
      // return service.request(originalRequest);//例如再重复请求一次
    }
    //  2.需要重定向到错误页面
    const errorInfo = error.response
    if (errorInfo) {
     console.log(errorInfo)
    }
    return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
  }
)

// response 拦截器
instance.interceptors.response.use(
  response => {
    // console.log('response', response)
    let data
    // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
    if (response.data === undefined) {
      data = response.request.responseText ? JSON.parse(response.request.responseText) : ''
    } else {
      data = response.request.response ? JSON.parse(response.request.response) : ''
    }
    console.log(444444, data)
    if(data.code == 2){
      localStorage.clear()
      location.reload()
    }
    return data
  },
  err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误'
          break

        case 401:
          // console.log(6565656)
         // Message.error('未授权，请重新登录')
        //   tool.clearStorage(['userMess', 'token', 'rouList'])
          break
        case 403:
          err.message = '拒绝访问'
          break

        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`
          break

        case 408:
          err.message = '请求超时'
          break

        case 500:
          err.message = '服务器内部错误'
          break

        case 501:
          err.message = '服务未实现'
          break

        case 502:
          err.message = '网关错误'
          break

        case 503:
          err.message = '服务不可用'
          break

        case 504:
          err.message = '网关超时'
          break

        case 505:
          err.message = 'HTTP版本不受支持'
          break

        default:
      }
    }
    if (err.message === 'Network Error') { // 网络出现问题
      // console.log('网络异常 请切换网络环境')
    } else {
      //err.message
      return Promise.reject(err) // 返回接口返回的错误信息
    }
  }
)
const commonParams = {}
const apiRequest = function (instance) {
  this.instance = instance
  this.handleDate = function (data, ifEncrypt) {
    const l = Object.assign(data, commonParams)
    ifEncrypt ? this.instance.prototype.ifEncrypt = ifEncrypt : this.instance.prototype.ifEncrypt = false
    if (ifEncrypt === 'formData') {
      var formData = new FormData()
      Object.keys(l).forEach((item) => {
        formData.append(item, l[item])
      })
      return formData
    } else if (ifEncrypt === 'JSON') {
      // return {encryptList.Encrypt(JSON.stringify(l))};
    } else if (ifEncrypt === 'Object') {
      return data
    } else if (ifEncrypt) {
      return { param: JSON.stringify(l) }
    } else {
      return { }
    }
  }
}
apiRequest.prototype.$post = function (url, data = {}, ifEncrypt, loadingFlag) {
 // data = this.handleDate(data, ifEncrypt)
  return this.instance.post(url, data, { loading: loadingFlag })
}
apiRequest.prototype.$get = function (url, params = {}) {
  //data = this.handleDate(data)
  return this.instance.get(url, {params})
}
apiRequest.prototype.$postNew = function (url, data = {}, updatUrl = '') {
  data = this.handleDate(data, 'Object')
  return this.instance.post(url, data, { updatUrl })
}
apiRequest.prototype.$getNew = function (url, data = {}, updatUrl = '') {
  data = this.handleDate(data, 'Object')
  return this.instance.get(url, data, { updatUrl })
}
// eslint-disable-next-line new-cap
export const apiRequestList = new apiRequest(instance)

// Vue.use({
//   install (vue, apiRequestList) {
//     Vue.prototype.$post = function (url, requireData, isUpdate) {
//       return apiRequestList.$post(url, requireData)
//     }
//     Vue.prototype.$get = function (url, requireData, isUpdate) {
//       return apiRequestList.$get(url, requireData)
//     }
//     Vue.prototype.$upload = function (requireData) {
//       return axios(tool.extend({
//         cancelToken: new axios.CancelToken(requireData.unloadCancelBack)
//       }, requireData))
//     }
//   }
// }, apiRequestList)

